<template>
  <div class="relative">
    <bar-chart
      :series="getSeries"
      class="h-full"
      horizontal
    />
  </div>
</template>

<script>
import LandingStatsRepository from "@/repositories/LandingStatsRepository";
import BarChart from "@/components/elements/charts/BarChart.vue";

export default {
  name: "LandingOrderStatsChart",
  components: {BarChart},
  data: () => ({
    responseData: []
  }),
  created() {
    LandingStatsRepository
      .getOrderStats()
      .then((res) => this.responseData = res.data.data);
  },
  computed: {
    getSeries() {
      const series = this.responseData.flatMap(d => {
        return {
          x: this.$date(d.date).format('dddd DD.MM'),
          y: d.total,
        };
      });

      return [{
        name: 'Commandes',
        data: series
      }];
    },
  },
}
</script>

<style scoped>

</style>